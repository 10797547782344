import React, { useRef, useState } from "react"
import { Link, useStaticQuery, graphql,  } from "gatsby"
import { AnimatedLink } from "@components/shared"
import { findInString } from "./logic"
import { withHtml } from "@components/logic"
import {
  wrapper,
  wrapper__links,
  wrapper__links__link,
} from "./blog.module.scss"
const H6 = withHtml("h6")
import ArrowSVG from "../../../templates/blog/icons/chevron_right.svg"
import Web from "./icons/web.svg"
import Mentor from "./icons/mentor.svg"
import _ from "lodash"
interface SearchProps {
  title: string
  slug: string
  type: string
  name: string
  image: string
}

const SearchListElem: React.FC<SearchProps> = ({
  title,
  slug,
  type,
  image,
}): JSX.Element => {
  // dodać type dla kategorii żeby prowadziło do podstroy kategorii w blogu

  if (type === "product") {
    return (
      // produkt niech prowadzi na odpowiedni # - niech przenosi na stronę roadmap lub czesnych
      <Link
        to={slug.includes("roadmapy") ? "/roadmaps/#roadmaps" : "/#pricing"}
        className={wrapper__links__link}
      >
        <H6>{title}</H6>
        <img
          src={title.includes("Dla") ? Mentor : Web}
          alt="web"
          style={{ width: "60px", height: "60px", marginLeft: "20px" }}
        ></img>
      </Link>
    )
  }

  return (
    <Link to={`/blog/${slug}/`} className={wrapper__links__link}>
      <H6>{_.truncate(title, { length: 50 })}</H6>
      <img src={image} alt="product"></img>
    </Link>
  )
}

const Search: React.FC = (): JSX.Element => {
  const [state, setState] = useState({ phrase: "", filtered: [] })

  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        posts(first: 1000) {
          nodes {
            id
            slug
            title(format: RENDERED)
            content(format: RENDERED)
            featuredImage {
              node{
                id
                srcSet
                sourceUrl
                sizes
              }
            }
          }
        }
        products(first: 1000) {
          nodes {
            id
            slug
            name
            description
            shortDescription(format: RENDERED)
            status
          }
        }
        pages(first: 1000) {
          nodes {
            id
            title(format: RENDERED)
            slug
            content(format: RENDERED)
          }
        }
      }
    }
  `)
  const { posts, products, pages, categories } = WP
  const publishedProducts = products.nodes.filter(
    (el) => el.status === "publish"
  )
  const _posts = posts.nodes.map((el) => {
    return {
      id: el.id,
      title: el.title,
      type: "post",
      slug: el.slug,
      content: el.content,
      image: el.featuredImage.node.sourceUrl,
    }
  })

  const _products = publishedProducts.map((el) => {
    return {
      id: el.id,
      title: el.name,
      slug: el.slug,
      type: "product",
      content: el.description + " " + el.shortDescription,
    }
  })

  const _pages = pages.nodes.map((el) => {
    return {
      id: el.id,
      title: el.title,
      slug: el.slug,
      type: "page",
      content: el.content || "",
    }
  })

  const all = [..._products, ..._posts]
  const setPhrase = (ev) => {
    let phrase = ev.target.value
    let _filtered = []
    let random = null
    if (phrase === "random()") {
      random = all[Math.floor(Math.random() * all.length)]
      _filtered = [random]
    } else if (phrase.length >= 2) {
      _filtered = all.filter((el) => {
        return [findInString(el.title, phrase)].includes(true)
      })
    }

    setState({
      phrase: ev.target.value,
      filtered: _filtered,
    })
  }
  return (
    <div className={wrapper}>
      <input
        type="text"
        value={state.phrase}
        onChange={(ev) => {
          setState({
            phrase: ev.target.value,
          })
          setPhrase(ev)
        }}
        placeholder={"Napisz czego szukasz lub wpisz random()"}
      />
      <div className={wrapper__links}>
        {state.phrase &&
          state.filtered.map((el, k) => {
            return <SearchListElem {...el} key={k} i={k} />
          })}
      </div>
    </div>
  )
}

export default Search
