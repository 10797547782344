import React from "react"
import { loader__blue, loader__white } from "./styles/loader.module.scss"
interface Props {
  blue?: boolean
}

const Loader: React.FC<Props> = ({ blue }): JSX.Element => {
  return (
    <div className={blue ? loader__blue : loader__white}>
      {[...Array(4).keys()].map((_, i) => (
        <div key={i} />
      ))}
    </div>
  )
}

export default Loader
