import { Formik } from 'formik';
import React, { Fragment, useContext, useEffect } from 'react';
import { createOrder, initPayment } from "@components/ecommerce";
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    CustomerCtx,
    CartCtx,
    AppCtx,
    useContextState,
    useActions
} from '@components/contexted';

const StepTwoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Title = styled.h3`
    font-size: 22px;
`;

const Button = styled.button`
    padding: 16px 32px;
    border: 1px solid black;
    border-radius: 0;
`;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px 16px;
    border: ${(props) => (props.error ? '1px solid #f00' : ' 1px solid black')};
    border-radius: 0;
`;

const ErrorMessage = styled.p`
    color: #f00;
    font-size: 14px;
`;

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Imię jest wymagane'),
    last_name: Yup.string().required('Nazwisko jest wymagane'),
    address_1: Yup.string().required('Adres jest wymagany'),
    city: Yup.string().required('Miasto jest wymagane'),
    postcode: Yup.string()
        .matches(/^[0-9]{2}-[0-9]{3}$/, {
            message: 'Kod pocztowy jest nieprawidłowy',
        })
        .required('Kod pocztowy jest wymagany'),
    email: Yup.string()
        .email('Nieprawidłowy email')
        .required('Email jest wymagany'),
});

const initialValue = {
    first_name: '',
    last_name: '',
    address_1: '',
    city: '',
    postcode: '',
    email: '',
};

const placeholders = {
    first_name: 'Imie',
    last_name: 'Nazwisko',
    address_1: 'Adres',
    city: 'Miasto',
    postcode: 'Kod pocztowy',
    email: 'Adres email',
};

const StepTwo: React.FC = (props): JSX.Element => {
    const { state, setState } = props
    const { dispatch } = useContext(CartCtx);
    const cart = useContext(CartCtx)
    const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
    const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
        "setOrderFromApi",
        "onOrderApiError",
    ])
    const { imBusy } = useContextState(AppCtx, "imBusy")

    useEffect(() => {
        dispatch({
            type: 'resetCart',
        }),
        dispatch({
            type: 'addToCart',
            payload: state,
            });
    }, [state])


    return (
        <StepTwoContainer>
            <Title>Dane do przesyłki</Title>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => 
                    {
                    if (!cart.state.orderInApi.id) {
                        createOrder({
                            cart: cart.state,
                            customer: values,
                            initLoading: loading,
                        })
                        .then((data) => {
                            loaded()
                            setOrderFromApi(data)
                        })
                        .catch(({ data }) => {
                            loaded()
                            onOrderApiError(data)
                        })
                    } 
                    else {
                        initPayment({ cart, initLoading: loading })
                            .then((payment) => {
                                loaded();
                                window.location.href = payment.redirectUri;
                            })
                            .catch((payment) => {
                                loaded();
                                console.log('err', payment);
                            });
                    }
                    }
                }
                validateOnChange={false}>
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        {Object.keys(values).map((value) => (
                            <Fragment key={value}>
                                <Input
                                    key={value}
                                    type={value === 'email' ? 'email' : 'text'}
                                    name={value}
                                    id={value}
                                    value={values[value]}
                                    onChange={handleChange}
                                    placeholder={placeholders[value]}
                                    error={errors[value]}
                                />
                                {errors[value] && (
                                    <ErrorMessage>{errors[value]}</ErrorMessage>
                                )}
                            </Fragment>
                        ))}
                        <Button type="submit">Zamawiam</Button>
                    </Form>
                )}
            </Formik>
        </StepTwoContainer>
    );
};

export default StepTwo;
