import { WPApi } from '@api';
import { AnimatedButtonTwo, AnimatedSectionTwo } from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Imie jest wymagane!'),
    email: Yup.string()
        .email('Podaj poprawny adres email!')
        .required('Email jest wymagany!'),
    message: Yup.string().required('Wiadomość jest wymagana!'),
});

const initialValue = {
    name: '',
    email: '',
    message: '',
};

const placeholderValue = {
    name: 'Twoje imię',
    email: 'Twój adres email',
    message: 'O co chcesz nas zapytać?',
};

const SectionWrapper = styled(AnimatedSectionTwo)`
    max-width: 1320px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: 100%;
    padding: 16px;
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid ${(props) => (props.error ? '#f00' : '#000')};
    border-radius: 0;
    padding: 8px 16px;
    font-family: Cabin;
    font-size: 14px;
`;

const H2 = styled.h2`
    font-size: 28px;
    text-align: center;
`;

const Textarea = styled(Input)`
    resize: none;
    height: 160px;
`;

const Button = styled(AnimatedButtonTwo)`
    border: 1px solid black;
    border-radius: 0;
    padding: 16px 32px;
    width: 100%;
    transition: background 250ms ease;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

const ErrorMessage = styled.p`
    color: #f00;
    font-size: 14px;
`;

const Contact: React.FC = (): JSX.Element => {
    const [messageStatus, setMessageStatus] = useState({
        err: false,
        isLoading: false,
        response: '',
    });
    return (
        <SectionWrapper>
            <H2>Kontakt</H2>
            <Formik
                initialValues={initialValue}
                validationSchema={ValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    if (messageStatus.isLoading) {
                        return;
                    }
                    setMessageStatus((prev) => ({
                        ...prev,
                        isLoading: true,
                        err: false,
                    }));
                    WPApi.sendMessage(values.name, values.email, values.message)
                        .then((data) => {
                            setMessageStatus((prev) => ({
                                ...prev,
                                isLoading: false,
                                message: data.message,
                            }));
                            resetForm();
                        })
                        .catch((err) => {
                            setMessageStatus({
                                isLoading: false,
                                err: true,
                                response: err.message,
                            });
                        });
                }}
                validateOnChange={false}
                validateOnBlur={false}>
                {({ handleChange, handleSubmit, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        {Object.keys(initialValue).map((key) => {
                            return key === 'message' ? (
                                <>
                                    <Textarea
                                        as="textarea"
                                        onChange={handleChange}
                                        name={key}
                                        id={key}
                                        placeholder={placeholderValue[key]}
                                        error={errors[key]}
                                    />
                                    <ErrorMessage>
                                        {errors[key] && errors[key]}
                                    </ErrorMessage>
                                </>
                            ) : (
                                <>
                                    <Input
                                        type={
                                            key === 'email' ? 'email' : 'text'
                                        }
                                        onChange={handleChange}
                                        name={key}
                                        id={key}
                                        placeholder={placeholderValue[key]}
                                        error={errors[key]}
                                    />
                                    <ErrorMessage>
                                        {errors[key] && errors[key]}
                                    </ErrorMessage>
                                </>
                            );
                        })}
                        <Button type="submit">Wyślij</Button>
                    </Form>
                )}
            </Formik>
        </SectionWrapper>
    );
};

export default Contact;
