import pl from 'date-fns/locale/pl';
import React, { useState } from 'react';
import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { sliceBack, isSunday, minDays, cleanPrice } from './logic';

const StepOneContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Title = styled.h3`
    font-size: 22px;
`;

const Calories = styled.div`
    width: 100%;
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;

    div {
        width: 100px;

        display: flex;
        justify-content: center;
    }
`;

const Input = styled.input`
    margin-right: 4px;
`;

const Button = styled.button`
    border: 1px solid black;
    padding: 16px 32px;
    border-radius: 0;
`;

const StartDate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ErrorMessage = styled.p`
    color: #f00;
    font-size: 14px;
    display: ${(props) => (props.error ? 'block' : 'none')};
`;

const Price = styled.h4`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    margin-bottom: 32px;
    font-size: 28px;
`;

interface IState {
    variation?: {} | null;
    startDate: Date | [Date | null, Date | null] | null;
}

const StepOne: React.FC = ({
    selectedProduct,
    setStep,
    setState,
}): JSX.Element => {
    const [errorState, setErrorState] = useState<boolean>(false);
    const [currentProduct, setCurrentProduct] = useState<IState>({
        variation: null,
        startDate: minDays(2),
    });

    registerLocale('pl', pl);

    const { variations } = selectedProduct;

    const onChangeRadio = (event) => {
        setCurrentProduct((prevState) => ({
            ...prevState,
            variation: variations.nodes.filter(
                (variant) => variant.id === event.target.value,
            )[0],
        }));
    };

    const validateToNextStep = () => {
        if (!validateInput()) {
            const { variation } = currentProduct;

            setState((prevState) => ({
                ...prevState,
                product_id: selectedProduct.databaseId,
                variation_id: variation.databaseId,
                name: variation.name,
                slug: selectedProduct.slug,
                price: cleanPrice(variation.price),
                value: cleanPrice(variation.price),
                regularPrice: cleanPrice(variation.regularPrice),
                regularValue: cleanPrice(variation.regularPrice),
            }));
        }
    };

    const validateInput = () => {
        if (currentProduct.variation === null) {
            setErrorState(true);
            return true;
        } else {
            setErrorState(false);
            setStep(2);
            return false;
        }
    };

    return (
        <StepOneContainer>
            <Title>Kaloryczność</Title>

            <Calories role="group">
                {variations.nodes.map((option) => (
                    <div key={option.id}>
                        <Input
                            type="radio"
                            id={option.id}
                            name="kcal"
                            value={option.id}
                            onChange={onChangeRadio}
                        />
                        <label htmlFor={option.id}>
                            {sliceBack(option.name, 4)}
                        </label>
                    </div>
                ))}
                <ErrorMessage error={errorState}>
                    Musisz wybrać kaloryczność!
                </ErrorMessage>
            </Calories>

            <StartDate>
                <CustomDatePicker
                    setCurrentProduct={setCurrentProduct}
                    currentProduct={currentProduct}
                />
            </StartDate>

            <Price isVisible={currentProduct?.variation?.price}>
                {currentProduct?.variation?.price &&
                    cleanPrice(currentProduct.variation.price)}{' '}
                PLN
            </Price>

            <Button onClick={validateToNextStep}>Dalej</Button>
        </StepOneContainer>
    );
};

const StyledDatePicker = styled.button`
    border: 1px solid black;
    border-radius: 0;
    padding: 8px 16px;
    margin-bottom: 32px;
`;

const CustomDatePicker = ({ setCurrentProduct, currentProduct }) => {
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <StyledDatePicker onClick={onClick} ref={ref}>
            {value}
        </StyledDatePicker>
    ));

    return (
        <DatePicker
            onChange={(date) =>
                setCurrentProduct((prevState) => ({
                    ...prevState,
                    startDate: date,
                }))
            }
            filterDate={isSunday}
            minDate={minDays(2)}
            selected={currentProduct.startDate || minDays(2)}
            locale="pl"
            popperPlacement={'top'}
            customInput={<CustomInput />}
            dateFormat="dd.MM.yyyy"
        />
    );
};

export default StepOne;
