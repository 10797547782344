export const minDays = (sub: number): Date => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + sub));
};

export const isSunday = (date: Date) => {
    const day = date.getDay();
    return day !== 0;
};

export const cleanPrice = (price) => {
    const _p = price.replace(' ', '');
    return parseFloat(_p.split('&nbsp;')[0]);
};

export const sliceBack = (str: string, howMany: number): string => {
    return str.slice(str.length - howMany);
};
