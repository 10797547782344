import React, { useEffect, useState } from "react"
import { useActions, CartCtx, useContextState } from "@components/contexted"
import { WPApi } from "@api"
import {
  modal__content__coupon,
  modal__content__info,
  modal__content__payu,
} from "../styles/paymentmodal.module.scss"
import PayuLogo from "../images/payu.png"
const Discount = (props) => {
  const { coupon, items, sum } = useContextState(CartCtx, [
    "coupon",
    "items",
    "sum",
  ])
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const [state, setState] = useState({ discountValue: 0, msg: "" })

  useEffect(() => {
    if (coupon.length > 3) {
      WPApi.getDiscount(items, coupon).then((data) => {
        if (data.discountValue > 0) {
          setState({
            msg:"",
            discountValue: data.discountValue,
          })
        } else {
          setState({
            msg:"Wprowadzony kupon jest nieprawidłowy.",
            discountValue: 0
          })
        }
      })
    } 

  }, [items, coupon ])

  return (
    <>
      <div className={modal__content__coupon}>
        <label>Miejsce na Twój kupon:</label>
        <input
          placeholder="np. 492231"
          onChange={(ev) => {
            setCoupon(ev.target.value)
          }}
          value={coupon}
        />
        {state.msg ? (
        <h5>{state.msg}</h5>
        ):(
          // <h5>&nbsp;</h5>
          null
        )}

      </div>
      <div className={modal__content__info}>
        <p>
          Nazwa produktu: <b>{items[0].name}</b>
        </p>
        <p>
          Cena: <b>{sum.val}PLN</b>
        </p>
        <p>
          Zniżka: <b>{state.discountValue.toFixed(2)}PLN</b>
        </p>
      </div>
      <div className={modal__content__payu}>
      <img src={PayuLogo} />
        <p>Dzięki PayU, mozesz rozbić płatnośc za naukę na wygodne raty.</p>
      </div>
    </>
  )
}

export default Discount
