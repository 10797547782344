import React from "react"
import { GitHubApi } from "@api"
import GitHubLogin from "react-github-login"
import { CustomerCtx, useActions, useContextState } from "@components/contexted"
import { Field } from "formik"
import { AnimatedButton } from "@components/shared"
import { modal__content__control } from "./styles/paymentmodal.module.scss"

export const defaultValues = () => {
  let ctx = null
  if (typeof window !== "undefined") {
    ctx = localStorage.getItem("CustomerCtx")
  }
  return ctx
    ? JSON.parse(ctx)
    : {
        first_name: "",
        last_name: "",
        address_1: "",
        address_2: "",
        city: "",
        postcode: "",
        country: "",
        email: "",
        note: "",
      }
}

const StepThree = ({ formik: { errors, touched } }) => {
  return (
    <>
      <div className={modal__content__control}>
        <label>Ulica:</label>
        <Field name="address_1" type="text" placeholder="np. Miejska 4" />
        {errors.address_1 && touched.address_1 ? (
          <p>{errors.address_1}</p>
        ) : null}
      </div>
      <div className={modal__content__control}>
        <label>Miasto:</label>
        <Field name="city" type="text" placeholder="np. Warszawa" />
        {errors.city && touched.city ? <p>{errors.city}</p> : null}
      </div>
      <div className={modal__content__control}>
        <label>Kod pocztowy:</label>
        <Field name="postcode" type="text" placeholder="np. 00-001" />
        {errors.postcode && touched.postcode ? <p>{errors.postcode}</p> : null}
      </div>
    </>
  )
}

export default StepThree
