import {
    CustomerCtx,
    useActions,
    CartCtx,
    AppCtx,
    useContextState,
} from '@components/contexted';
import { StructuredData } from '@components/layout';
import {
    AnimatedButton,
    AnimatedSection,
    AnimatedSectionTwo,
} from '@components/shared';
import { deepEqual } from 'node:assert';
import React, {
    forwardRef,
    Ref,
    useRef,
    useCallback,
    useState,
    useContext,
    useEffect,
} from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import PaymentModal from './components/Payment/PaymentModal';
import SingleSlide from './components/Payment/SingleSlide';
import CheckSVG from './icons/check.svg';
import MinusSVG from './icons/minus.svg';
import PlusSVG from './icons/plus.svg';
import { container } from './styles/offer.module.scss';

const cleanPrice = (price) => {
    return parseFloat(price.replace(/[$,]+/g, ''));
};

interface Props {}

interface LevelProps {
    levels: any;
    level: any;
    setLevel: any;
}

const SingleAttribute = (props) => {
    const { text } = props;
    return (
        <div>
            <img src={CheckSVG} alt="check"></img>
            <p>{text}</p>
        </div>
    );
};

const SingleCard = (props) => {
    const { dispatch } = useContext(CartCtx);
    const {
        product,
        state,
        setState,
        isSelected,
        onModalOpen,
        selected,
        setSelected,
        index,
    } = props;
    const { name, description, regularPrice, attributes, id, image } = product;
    const { coupon } = useContextState(CartCtx, ['coupon']);
    const { setCoupon, changeProductQty } = useActions(CartCtx, [
        'setCoupon',
        'changeProductQty',
    ]);
    const productAttributes = attributes.nodes[0].options;

    const [single, setSingle] = useState({
        product_id: product.databaseId,
        name: product.name,
        slug: product.slug,
        price: cleanPrice(product.regularPrice),
        regularPrice: cleanPrice(product.regularPrice),
        regularValue: cleanPrice(product.regularPrice),
        value: cleanPrice(product.regularPrice),
        quantity: 1,
        image: product.image,
    });

    const changeQty = (val) => {
        const _number = Number(val);
        if (_number <= 0 || Number.isNaN(_number)) {
            return false;
        } else {
            setSingle({
                ...single,
                quantity: _number,
                value: _number * single.price,
                regularValue: _number * single.regularPrice,
            });
        }
    };

    const addToCart = () => {
        dispatch({
            type: 'resetCart',
        }),
            dispatch({
                type: 'addToCart',
                payload: single,
            });
    };

    useEffect(() => {
        if (!isSelected) {
            changeQty(1);
        }
    }, [isSelected]);

    return <span></span>;
};

const OfferSection = styled(AnimatedSectionTwo)`
    margin: 0 auto 100px;
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    position: relative;

    h1 {
        text-align: center;
        font-size: 34px;
        margin-bottom: 16px;
    }
`;

const Offer: React.ForwardRefRenderFunction<Ref<HTMLElement>> = (
    props,
    ref,
): JSX.Element => {
    const { products } = props;
    const { dispatch } = useContext(CartCtx);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [isOpen, setOpen] = useState(false);

    console.log('ppp', products);

    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <OfferSection>
            <div id="pricing" style={{ position: 'absolute', top: '-150px' }} />
            <h1>Produkty</h1>

            <Slider {...sliderSettings}>
                {products.map((product) => (
                    <SingleSlide
                        product={product}
                        key={product.id}
                        index={product.id}
                        setOpen={setOpen}
                        setSelectedProduct={setSelectedProduct}
                    />
                ))}
            </Slider>

            <PaymentModal
                isOpen={isOpen}
                setOpen={setOpen}
                selectedProduct={selectedProduct}
            />
        </OfferSection>
    );
};

export default forwardRef(Offer);

// <StructuredData>
// {{
//     '@context': 'http://schema.org/',
//     '@type': 'Product',
//     name: 'Mentoring ' + name,
//     sku: ' ',
//     brand: {
//         '@type': 'Thing',
//         name: 'Localhost Group',
//         url: 'https://academy.localhost-group.com',
//     },
//     aggregateRating: {
//         '@type': 'AggregateRating',
//         ratingValue: '5',
//         reviewCount: '387',
//     },
//     offers: {
//         '@type': 'Offer',
//         priceCurrency: 'PLN',
//         price: cleanPrice(product.regularPrice),
//         priceValidUntil: '2021-01-25',
//         url: 'https://academy.localhost-group.com/#pricing',
//         availability: 'http://schema.org/InStock',
//         seller: {
//             '@type': 'Organization',
//             name: 'Localhost Group',
//             logo: 'http://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
//         },
//     },
// }}
// </StructuredData>
