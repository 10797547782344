import { AnimatedButtonTwo } from '@components/shared';
import React from 'react';
import styled from 'styled-components';

import DietPNG from './images/diet.png';

const SingleSlideContainer = styled.div`
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img``;

const Name = styled.h2`
    text-align: center;
    font-size: 28px;
`;

const Description = styled.p`
    font-size: 18px;
    line-height: 22px;
`;

const Button = styled.button`
    border: 1px solid black;
    padding: 16px 32px;
    border-radius: 0;
    margin-top: 16px;
`;

const SingleSlide: React.FC = ({
    product,
    setOpen,
    setSelectedProduct,
}): JSX.Element => {
    const openModal = () => {
        setSelectedProduct(product);
        setOpen(true);
    };

    return (
        <SingleSlideContainer>
            <Image src={DietPNG} alt="diet" />
            <Name>{product.name}</Name>
            <Description>
                Sint officia amet velit proident officia sit proident. Ipsum
                commodo sunt incididunt aliqua eu duis mollit dolor sunt duis
                velit id aute.
            </Description>
            <Button onClick={openModal}>Sprawdź diete</Button>
        </SingleSlideContainer>
    );
};

export default SingleSlide;
