import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import DietPNG from './images/diet.png';
import { cleanPrice } from './logic';

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        zIndex: 50,
    },
    content: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        right: '20px',
        bottom: '20px',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '10px',
    },
};

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    margin: 32px 0;
`;

const Name = styled.h2`
    font-size: 28px;
`;

const Calories = styled.div`
    border: 2px solid red;
    width: 100%;
`;

const Input = styled.input``;

const CaloriesGroup = styled.div``;

const Label = styled.label``;

interface IState {
    value: null;
    regularValue: null;
    quantity: number;
    product_id: number | null;
    variation_id: number | null;
    variant?: null;
    sale: boolean;
    price: number;
    regularPrice: number;
    unit: string;
    name: string;
    slug: string;
    image?: null;
}

const PaymentModal: React.FC = ({
    isOpen,
    setOpen,
    selectedProduct,
}): JSX.Element => {
    const [state, setState] = useState<IState>({
        value: null,
        regularValue: null,
        quantity: 1,
        product_id: null, //
        variation_id: null,
        variant: null,
        sale: false,
        price: 0,
        regularPrice: 0,
        unit: 'PLN',
        name: '',
        slug: '',
        image: null,
    });
    const [step, setStep] = useState<number>(1);

    console.log(state);

    return (
        <Modal
            style={customStyle}
            isOpen={isOpen}
            onRequestClose={() => {
                setOpen(false);
                setStep(1);
            }}>
            <ModalWrapper>
                <Name>{selectedProduct.name}</Name>
                <Image src={DietPNG} alt="diet" />
                {step === 1 && (
                    <StepOne
                        selectedProduct={selectedProduct}
                        setStep={setStep}
                        setState={setState}
                    />
                )}
                {step === 2 && <StepTwo state={state} setState={setState}/>}
            </ModalWrapper>
        </Modal>
    );
};

export default PaymentModal;
